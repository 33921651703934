import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/layout/seo"
import Hero from "../components/Global/secondLevelHero/secondLevelHero"
import * as styles from "../styles/ae-reporting.module.scss"
import Footnotes from "../components/Global/footnotes/footnotes"
import Footer from "../components/layout/footer"
import Layout from "../components/layout/layout"


const AeReporting = ({ data }) => {

  const content = data.allContentfulAdverseEventsPage.nodes[0]

  let bannerData = {
    breadcrumbs: content.hero.breadcrumb,
    title: content.hero.title,
    content: content.hero.content,
    backgroundImage: content.hero.backgroundImage,
  }

  const BreadcrumbsList = bannerData.breadcrumbs.map(breadcrumb => {
    if(breadcrumb.url) {
      return (  
        <Link className={styles.banner__text__breadcrumbs__item} dangerouslySetInnerHTML={{ __html: breadcrumb.title }} to={breadcrumb.url}></Link>
      )
    } else {
      return (  
        <div className={styles.banner__text__breadcrumbs__item} dangerouslySetInnerHTML={{ __html: breadcrumb.title }} />
      )
    }
  });

  let adverseEvents = {
    title: content.adverseEvents.title,
    content: content.adverseEvents.content
  }

  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: [],
  }

  return (
    <Layout>
      <Seo title="Adverse event reporting" />

      <div className={styles.banner}>
        <div className={styles.banner__text}>
          <div className={styles.banner__text__breadcrumbs}>
            {BreadcrumbsList}
          </div>
          <h1 className={styles.banner__title}>{bannerData.title}</h1>
        </div>
      </div>

      <div className={styles.adverseEvents}>
        <div dangerouslySetInnerHTML={{ __html: adverseEvents.content.childMarkdownRemark.html }} />
      </div>

      <div className={styles.adverseEvents__outro}>
        <div dangerouslySetInnerHTML={{ __html: content.outroText.childMarkdownRemark.html }} />
      </div>
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
    query {
      allContentfulAdverseEventsPage {
        nodes {
          title
          hero {
            breadcrumb {
              title
              url
            }
            title
            content {
              childMarkdownRemark {
                html
              }
            }
            backgroundImage {
              gatsbyImageData(
                layout: FULL_WIDTH
              )
              title
            }
          } 
          adverseEvents {
            title
            content {
              childMarkdownRemark {
                html
              }
            }
          }  
          outroText {
            childMarkdownRemark {
              html
            }
          }   
          jobCodeDate    
        }
      }
    }
`


export default AeReporting